import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { graphql } from 'gatsby'
import ProductSlider from '../components/sliders/ProductSlider'
import FilterFindYourWatch from '../components/products/FilterFindYourWatch'
import { handleFacetClick } from '../components/products/FilterPanel'
import Preloading from '../components/products/PreloadingFindYourWatch'
import PropTypes from 'prop-types'
import {
  algoliaSearch,
  extractFilter,
  mergeFacets,
  serializeFacets,
  deserializeFacets,
} from '../helpers/AlgoliaClient'

const FindYourWatch = ({ location, data: { facets: initialFacets, items, category } }) => {
  // const [page, setPage] = useState(0)
  const [hits, setHits] = useState([])
  const [facets, setFacets] = useState(initialFacets)
  const [facetFilters, setFacetFilters] = useState(deserializeFacets(location.hash))
  const [searchPages, setSearchPages] = useState({
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
    itemCount: 0,
    searched: false,
  })

  useEffect(() => {
    if (!window) return
    const _facets = deserializeFacets(location.hash)
    extractFilter(_facets, 'search', true)
    const _keyword = ''
    const _sortBy = extractFilter(_facets, 'sort_by', false)
    let _page = parseInt(extractFilter(_facets, 'page'))
    if (Object.keys(_facets || {}).length === 0 && _keyword === '') {
      setFacets(initialFacets)
      setFacetFilters(_facets)
      setHits([])
      setSearchPages(s => {
        return {
          ...s,
          searched: false,
        }
      })
      return
    }
    // setPage(_page)
    setFacetFilters(_facets)
    const controller = new AbortController()
    algoliaSearch(_keyword, _page, _facets, _sortBy, controller, category).then(searchResponse => {
      if (controller.signal.aborted) return
      setFacets(prevFacets => mergeFacets(prevFacets, _facets, searchResponse))
      setHits(searchResponse.hits)
      setSearchPages({
        currentPage: searchResponse.page,
        perPage: searchResponse.hitsPerPage,
        totalCount: searchResponse.nbHits,
        itemCount: searchResponse.hits.length,
        searched: true,
      })
    })
    return () => {
      controller.abort()
    }
  }, [location.hash, setFacetFilters, setFacets, initialFacets, mergeFacets, category.strapi_id])

  const handleFacet = useCallback(
    (key, facet) => {
      if (!window) return
      window.location.hash = serializeFacets(
        handleFacetClick(key, facet, deserializeFacets(window.location.hash))
      )
    },
    [handleFacetClick, serializeFacets, deserializeFacets]
  )

  const pageInfo = searchPages.searched ? searchPages : items.pageInfo

  const size = Object.keys(facetFilters).length

  return (
    <Layout
      shop={true}
      className="mx-auto w-11/12 xl:w-10/12 pb-10 md:py-10"
      h1="Find Your Perfect Watch"
    >
      <Seo
        description={
          'Find Your Pefect preowned luxury Watch. Browse brands like Rolex, Cartier, Patek Philippe, Audemars Piguet and more'
        }
        title={'Find Your Pefect Watch'}
      />

      <div className={'flex flex-col md:flex-row gap-4 relative'}>
        <div className="w-full md:w-8/12 sticky top-0 z-20 bg-white py-4 ">
          <div className={'sticky top-8 flex flex-col md:flex-grow '}>
            <div className=" order-1 w-full overflow-hidden md:bg-gray-100 md:rounded-xl md:p-[calc(1rem+1vw)]">
              {hits.length !== items.nodes.length && hits.length > 0 ? (
                <ProductSlider products={hits} size={size} />
              ) : (
                <Preloading />
              )}
            </div>
            <p className="order-2 px-0 md:px-[27px] text-center block md:text-left w-full">
              {' '}
              {pageInfo.totalCount} results
            </p>
          </div>
        </div>
        <aside
          className={'md:w-4/12 xl:w-4/12 lg:w-[20rem] flex-none max-w-[30rem]'}
          itemScope
          itemType="https://schema.org/WPSideBar"
        >
          <FilterFindYourWatch
            facets={facets}
            facetFilters={facetFilters}
            handleFacet={handleFacet}
          />
        </aside>
      </div>
    </Layout>
  )
}

FindYourWatch.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    items: PropTypes.object.isRequired,
    facets: PropTypes.object.isRequired,
    category: PropTypes.object.isRequired,
  }).isRequired,
}

export const query = graphql`
  query {
    facets: allStrapiProduct(
      sort: { fields: stock_date, order: DESC }
      filter: { qty: { gt: 0 } }
    ) {
      ...FacetFilters
    }
    items: allStrapiProduct(sort: { fields: stock_date, order: DESC }) {
      nodes {
        name
        productType
        categories {
          name
          id
        }
        url_key: urlPath
        sku
        title
        small_image {
          name
          url
        }
        thumbnail {
          name
          url
        }
        brand
        model
        price
      }
      pageInfo {
        perPage
        pageCount
        totalCount
        itemCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
    category: strapiCategory(name: { eq: "Fine Luxury Watches" }) {
      strapi_id
      seo {
        metaTitle
        metaDescription
      }
      name
      description
      url: urlPath
    }
  }
`

export default FindYourWatch
